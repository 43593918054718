/* eslint-disable no-console */
import { getters } from '@/store/index.js';

const dependencies = {
  //'54-waschbecken': 'wallMountedArmature',
  '56-waschbeckenarmatur': 'wallMountedArmature',
};

const conflictedRoomDataList = [
  {
    roomSlug: '72-wohnraeume',
    slotGroupSlugs: ['242-parkett-72', '245-fliesen-72'],
    slaveRoomSlug: '228-wohnungsflur-abstellraum',
    slaveRoomSlotPageSlugs: ['231-wohnungsflur-228', '235-abstellraum-228'],
  },
  {
    roomSlug: '228-wohnungsflur-abstellraum',
    slotGroupSlugs: ['408-ebenfalls-parkett-228', '411-fliesen-228'],
    slaveRoomSlug: '228-wohnungsflur-abstellraum',
    slaveRoomSlotPageSlugs: ['235-abstellraum-228'],
  },
];

export const isConflictingRoomFlooring = (_roomSlug, _slotGroupSlug) => {
  const roomData = conflictedRoomDataList.find(_entry => _entry.roomSlug === _roomSlug);
  return (
    roomData &&
    roomData.slotGroupSlugs.some(_slug => _slug === _slotGroupSlug) &&
    roomData.slaveRoomSlotPageSlugs.some(_slug => !!getters.getSlotBySlotSlug(_slug))
  );
};

export const getConflictedRoomData = _roomSlug => {
  const roomData = conflictedRoomDataList.find(_entry => _entry.roomSlug === _roomSlug);
  return {
    roomSlug: roomData?.slaveRoomSlug,
    slotPageSlugs: roomData?.slaveRoomSlotPageSlugs,
  };
};

export const checkForConflictingProducts = (_roomSlug, _slotSlug, _productAttributes) => {
  if (dependencies[_slotSlug]) {
    return getters.getConflictingProductsByRoom(
      _roomSlug,
      _slotSlug,
      dependencies[_slotSlug],
      _productAttributes[dependencies[_slotSlug]]
    );
  }
};

//needs that references shit also
export const getConflictingSelection = _slotGroupSlug => {
  console.log('getConflictingSelection:', _slotGroupSlug);
  switch (_slotGroupSlug) {
    //72-Wohnraum
    case '242-parkett-72':
      return [
        //Flur
        {
          productSlotSlugs: ['11684-parkett-228', '11688-fussleisten-228'],
          referencedProductGroup: _slotGroupSlug,
          slotGroupSlug: '408-ebenfalls-parkett-228',
          slotSlug: '405-bodenbelag-228',
          unselectedSlotSlugs: [
            '11691-bodenfliesen-228',
            '15498-fugenfarben-228',
            '414-bodenfliesen-228',
            '15497-fugenfarben-228',
          ],
        },
        //Abstellraum
        {
          productSlotSlugs: ['11683-parkett-228', '11687-fussleisten-228'],
          referencedProductGroup: _slotGroupSlug,
          slotGroupSlug: '423-ebenfalls-parkett-228',
          slotSlug: '421-bodenbelag-228',
          unselectedSlotSlugs: [
            '11690-bodenfliesen-228',
            '15495-fugenfarben-228',
            '427-bodenfliesen-228',
            '15500-fugenfarben-228',
          ],
        },
      ];
    //72-Wohnraum
    case '245-fliesen-72':
      return [
        //Flur
        {
          productSlotSlugs: ['11691-bodenfliesen-228', '15498-fugenfarben-228'],
          referencedProductGroup: _slotGroupSlug,
          slotGroupSlug: '9272-ebenfalls-fliesen-228',
          slotSlug: '405-bodenbelag-228',
          unselectedSlotSlugs: [
            '11684-parkett-228',
            '11688-fussleisten-228',
            '414-bodenfliesen-228',
            '15497-fugenfarben-228',
          ],
        },
        //Abstellraum
        {
          productSlotSlugs: ['11690-bodenfliesen-228', '15495-fugenfarben-228'],
          referencedProductGroup: _slotGroupSlug,
          slotGroupSlug: '9301-ebenfalls-fliesen-228',
          slotSlug: '421-bodenbelag-228',
          unselectedSlotSlugs: [
            '11683-parkett-228',
            '11687-fussleisten-228',
            '427-bodenfliesen-228',
            '15500-fugenfarben-228',
          ],
        },
      ];

    //228-wohnungsflur-abstellraum - Flur
    case '408-ebenfalls-parkett-228':
      return [
        //Abstellraum
        {
          productSlotSlugs: ['11683-parkett', '11687-fussleisten'],
          referencedProductGroup: _slotGroupSlug,
          slotGroupSlug: '423-ebenfalls-parkett-228',
          slotSlug: '421-bodenbelag-228',
          unselectedSlotSlugs: [
            '11690-bodenfliesen-228',
            '15495-fugenfarben-228',
            '427-bodenfliesen-228',
            '15500-fugenfarben-228',
          ],
        },
      ];
    case '411-fliesen-228':
      return [
        //Abstellraum
        {
          productSlotSlugs: ['414-bodenfliesen-228', '15497-fugenfarben-228'],
          referencedProductGroup: _slotGroupSlug,
          slotGroupSlug: '425-fliesen-228',
          slotSlug: '421-bodenbelag-228',
          unselectedSlotSlugs: [
            '11683-parkett-228',
            '11687-fussleisten-228',
            '11690-bodenfliesen-228',
            '15495-fugenfarben-228',
          ],
        },
      ];

    default:
      console.log(
        '[conflictchecker] getConflictingSelection() error - _slotGroupSlug?: ',
        _slotGroupSlug
      );
      break;
  }
};
